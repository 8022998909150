$color_1: aliceblue;
$color_2: #fff;
$color_3: #28a745;
$background-color_1: #000000;
$background-color_2: #0069d9;
$background-color_3: #28a745;
$background-color_4: #218838;
$background-color_5: #ddd;
// @import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@300;400;600;800&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // text-transform: capitalize !important;

  //   font-family: 'Martel Sans', sans-serif;
}
@font-face {
  font-family: 'Cerebri Sans';
  src: url('./fonts/CerebriSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cerebri Sans';
  src: url('./fonts/CerebriSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.custom-header {
  color: #2a2a2a;
  font-weight: bold;
  text-align: center !important;
}
.css-1vh4ddf-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root .MuiIconButton-root {
  color: #000000 !important;
}
.mark_attendance_info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding: 15px;
  input {
    width: 350px;
  }
}
.card_data_app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  p {
    font-size: 1.3rem;
    z-index: 99 !important;
  }
}
.card_data_icon {
  position: absolute;
  color: $color_1;
  opacity: 0.3;
  font-size: 3rem;
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 8rem;
  }
}
.image-upload-container {
  width: 100%;
  // max-width: 400px;
  margin: 10px auto;
  padding: 2rem;
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-upload-preview-container {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.image-upload-preview {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}
.image-upload-input {
  display: none;
}
.image-upload-label {
  // height: 60px;
  padding: 0 1rem;
  color: #212b36;
  // color: $color_2;
  border-radius: 3px;
  cursor: pointer;

  i {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
  span {
    font-size: 1rem;
    font-weight: 600;
  }
}
.image-upload-button {
  height: 40px;
  padding: 0 1rem;
  margin-left: 1rem;
  background-color: $background-color_3;
  color: $color_2;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: $background-color_4;
  }
}
#uploadForm {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.progress-container {
  width: 100%;
  height: 4px;
  margin-top: 1rem;
  position: relative;
}
.progress-bar {
  width: 100%;
  height: 4px;
  background-color: $background-color_5;
  border-radius: 2px;
  overflow: hidden;
}
.progress-indicator {
  height: 100%;
  background-color: $background-color_3;
  border-radius: 2px;
  transition: width 0.2s;
}
.progress-count {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
}
.upload-complete-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  color: $color_3;
  font-size: 1.2rem;
  font-weight: 600;
  i {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
}
.presentCard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.presentCardInner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 25px;
}

@media screen and (max-width: 600px) {
  .card_data_icon {
    text-align: center;
    width: 100%;
  }
}
.circleBar {
  position: absolute;
  top: 48%;
  left: 48%;
  transform: translate(-50%, -50%);
  color: #000000;
}
// Calender custom style
.fc .fc-button-primary:disabled {
  display: none !important;
}
.fc-toolbar-chunk {
  margin-right: 10px !important;
}
.fc .fc-scrollgrid-liquid {
  background-color: #f2f2f2;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #63eae1;
  border-radius: 10px;
  color: #fff;
}

.fc .fc-toolbar {
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 400;
}
.fc .fc-button-primary {
  background-color: transparent;
  border: none;
  color: #2065d1;
  border-radius: 50%;
  &:hover {
    background-color: rgba(222, 222, 222, 0.423);
    border: none;
    color: #2065d1;
  }
}
.fc .fc-toolbar-title {
  font-size: 1.4em;
  margin: 0px;
  font-weight: 400;
}
// .fc-day {
//   width: 70px;
//   height: 30px;
// }
.css-g61vyw {
  width: 100% !important;
  background-color: #2065d1;
  color: #fff;
  border-radius: 5px;
  padding-left: 2px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}
.calendar-container {
  width: 100%; /* Set the container width to 100% */
  max-width: 800px; /* Set a maximum width to limit how wide it can get */
  margin: 0 auto; /* Center the container horizontally */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .calendar-container {
    max-width: 100%; /* Full width for smaller screens */
  }
}
.all_style {
  width: 100% !important;
  background: rgb(255, 170, 0);
  color: white;
  border: none !important;
  border-radius: 5px;
  padding-left: 2px;
  box-shadow: rgb(75 75 75 / 35%) 0px -50px 36px -28px inset;
}
.staff_style {
  border: none !important;
  border-radius: 5px;
  padding-left: 2px;
  box-shadow: rgb(75 75 75 / 35%) 0px -50px 36px -28px inset;
  width: 100% !important;
  background: #7eff1a;
  color: black;
}
.staff_style {
  border: none !important;
  border-radius: 5px;
  padding-left: 2px;
  box-shadow: rgb(75 75 75 / 35%) 0px -50px 36px -28px inset;
  width: 100% !important;
  background: #7eff1a;
  color: black;
}
.user_style {
  border: none !important;
  border-radius: 5px;
  padding-left: 2px;
  color: #fff;
  box-shadow: rgb(75 75 75 / 35%) 0px -50px 36px -28px inset;
  width: 100% !important;
  background: purple;
}
// .fc-theme-standard th
// .fc-theme-standard td,
// .fc-theme-standard td {
//   border: none;
// }
// .fc-scrollgrid-liquid {
//   border-radius: 5px;
//   border: none;
// }
// .fc .fc-daygrid-day-top {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
// .fc-day {
//   height: 30px;
// }
// .fc .fc-scrollgrid-liquid {
//   border: none !important;
// }

// // staff sections
.staffAr.css-i4bv87-MuiSvgIcon-root {
  color: #000000 !important;
  font-size: 2rem !important;
}
#email_basic {
  text-transform: lowercase !important;
}

// verify otp section
.verification_sec {
  padding: 5rem 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}
.verification_sec input {
  // width: 8.7rem;
  width: 100%;
  text-align: center;
  height: 3rem;
  font-size: 2rem;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  color: #2f2f2f !important;
  font-weight: 600;
}

// staff dashboard style
.autocomplete-input {
  border: none; /* Remove the border */
}
